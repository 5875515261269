import React from "react";

export default function InputVehicles({
	pairVehicles,
	setPairVehicles,
	removeVehiclePair,
	t,
}) {
	// Function to handle the change in vehicle type
	const handleVehicleTypeChange = (index, e) => {
		const updatedPairVehicles = [...pairVehicles];
		updatedPairVehicles[index].vehicleType = e.target.value;
		setPairVehicles(updatedPairVehicles);
	};

	// Function to handle the change in number of vehicles
	const handleVehiclesPairChange = (index, e) => {
		const updatedPairVehicles = [...pairVehicles];
		updatedPairVehicles[index].numberVehicles = e.target.value;
		setPairVehicles(updatedPairVehicles);
	};
	return (
		<>
			{pairVehicles.map((vehiclePair, index) => (
				<div className="row my-1" key={index}>
					<div className="col-md-6 form-group mb-1">
						<select
							className="select-form-group form-control"
							aria-label="Select Vehicle"
							value={vehiclePair.vehicleType}
							onChange={(e) => handleVehicleTypeChange(index, e)}
							required
						>
							<option hidden value="">
								{t("contact.fields.select_vehicle.placeholder")}
							</option>
							<option value="Bici/Moto">
								{t("contact.fields.select_vehicle.option_one")}
							</option>
							<option value="Macchina/Carrello (<5m)">
								{t("contact.fields.select_vehicle.option_two")}
							</option>
							<option value="SUV/Minivan (5-7m)">
								{t("contact.fields.select_vehicle.option_three")}
							</option>
							<option value="Van/Camper (7-10m)">
								{t("contact.fields.select_vehicle.option_four")}
							</option>
							<option value="Camion/Yacht (+10m)">
								{t("contact.fields.select_vehicle.option_five")}
							</option>
						</select>
						<label className="form__label">
							{t("contact.fields.select_vehicle.vehicle_type")} *
						</label>
					</div>
					<div className="col-md-6 form-group mb-1 d-flex justify-content-between pb-0">
						<input
							type="number"
							value={vehiclePair.numberVehicles}
							onChange={(e) => handleVehiclesPairChange(index, e)}
							placeholder="0"
							id={`numberVehicles_${index}`}
							className="form-control"
							min="0"
							required
						/>
						<label className="form__label">
							{t("contact.fields.select_vehicle.number_vehicles")} *
						</label>
						{pairVehicles.length > 1 && (
							<button
								type="button"
								className="text-danger ms-2 btn btn-remove-vehicle"
								onClick={() => removeVehiclePair(index)} // Remove vehicle pair by index
							>
								<i className="bi bi-dash-circle-fill"></i>
							</button>
						)}
					</div>
				</div>
			))}
		</>
	);
}
