import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useState } from "react";
export default function IntPhoneInput({ onChange }) {
	// `value` will be the parsed phone number in E.164 format.
	// Example: "+12133734253".

	const [phoneNumber, setPhoneNumber] = useState();

	const handlePhoneChange = (phoneNumber) => {
		setPhoneNumber(phoneNumber);
		if (onChange) {
			onChange(phoneNumber); // Emit the value to the parent
		}
	};
	return (
		<PhoneInput
			defaultCountry="IT"
			international
			countrySelectProps={{ unicodeFlags: true }}
			placeholder="Enter phone number"
			value={phoneNumber}
			onChange={handlePhoneChange}
			name="customer_phone"
		/>
	);
}
