import React from "react";
import { Link } from "react-router-dom";

export function PricingItemCard({ item, t }) {
	return (
		<div className="col-lg-4">
			<div
				className={`pricing-item background-pricing-item d-flex flex-column justify-content-between ${item.classes} `}
			>
				<h3 className="text-dark mb-0">{t(item.translationKey)}</h3>
				{item.length && (
					<p className="text-dark text-center" style={{ fontSize: "18px" }}>
						{item.length}
					</p>
				)}
				<div>
					<div className="icon">
						<div className="icon">
							<img
								src={item.iconUrl}
								className="img-fluid w-75"
								alt=""
								data-aos="zoom-out"
								data-aos-delay="100"
							/>
						</div>
					</div>
					<h6 className="text-center">
						<span> {t("rates.price_from")} </span>
					</h6>
					<h4 className="text-dark mb-2">
						<sup>€</sup>
						{item.price}
						<span className="text-dark"> / {t("rates.price")}</span>
					</h4>

					<div className="text-center">
						<Link to="/home#contact" className="buy-btn">
							{t("rates.button_book")}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
