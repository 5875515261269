import { Hero } from "./hero";
import { Pricing } from "./pricing";
import { Faq } from "./faq";
import { Footer } from "./footer";
import { Services } from "./services";
import { ContactForm } from "./contact-form";
import { Maps } from "./maps";
import React, { useEffect, useRef } from "react";
// import Gallery from "./gallery";
import CTA from "./call-to-action";
import "../App.css";
import MediaQuery from "../helpers/media-query";
import { useLocation } from "react-router-dom";
import FerriesMap from "./ferries-map";
import Testimonials from "./testimonials";

export default function Home({ t }) {
	const location = useLocation();

	useEffect(() => {
		const hash = location.hash;
		const targetElementId = hash.slice(1); // Remove the '#' symbol from the hash

		if (targetElementId) {
			const targetElement = document.getElementById(targetElementId);

			if (targetElement) {
				targetElement.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location]);
	const activeSuccessModal = useRef();
	const activeErrorModal = useRef();
	const { forEmail } = MediaQuery();
	return (
		<>
			{/* <!-- ======= Hero Section ======= --> */}
			<Hero t={t} />

			{/* <!-- End Hero Section --> */}

			<main id="main">
				<CTA t={t} />

				<Services t={t} />

				<Pricing t={t} />

				<Faq t={t} />

				{/* <!-- ======= Contact Section ======= --> */}
				<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">
						<div className="section-header">
							<h2>{t("contact.title")}</h2>
							<p>{t("contact.description")}</p>
						</div>

						<div className="row gx-lg-0 gy-4">
							<div className="col-lg-5 col-xl-4">
								<div className="info-container d-flex flex-column align-items-center justify-content-center">
									<div className="info-item d-flex">
										<i className="bi bi-geo-alt flex-shrink-0"></i>
										<div>
											<h4>{t("contact.address")}:</h4>
											<p>Via dei materassai 1/b, Livorno 57121, Italia</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-envelope flex-shrink-0"></i>
										<div>
											<h4>Email:</h4>
											{forEmail ? (
												<p>
													info@<br></br>
													<span>parkingtransferlivorno.com</span>
												</p>
											) : (
												<p>info@parkingtransferlivorno.com</p>
											)}
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-phone flex-shrink-0"></i>
										<div>
											<h4>{t("contact.phone")}:</h4>
											<p>+39 375 696 6969</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}

									<div className="info-item d-flex">
										<i className="bi bi-clock flex-shrink-0"></i>
										<div>
											<h4>{t("contact.hours")}:</h4>
											<p>24/7</p>
										</div>
									</div>
									{/* <!-- End Info Item --> */}
								</div>
							</div>

							<ContactForm
								activeSuccessModal={activeSuccessModal}
								activeErrorModal={activeErrorModal}
								t={t}
							/>
							{/* <!-- End Contact Form --> */}
						</div>
					</div>
				</section>
				{/* <!-- End Contact Section --> */}
			</main>
			<Maps t={t} />
			<FerriesMap t={t} />
			<Testimonials t={t} />
			{/* <Gallery t={t} /> */}
			{/* <!-- ======= Footer ======= --> */}
			<Footer t={t} />
			{/* // <!-- Button trigger modal --> */}
			<button
				style={{ display: "none" }}
				type="button"
				className="btn btn-primary"
				data-toggle="modal"
				data-target="#succesModal"
				ref={activeSuccessModal}
			>
				Launch demo modal
			</button>
			<button
				style={{ display: "none" }}
				type="button"
				className="btn btn-primary"
				data-toggle="modal"
				data-target="#errorModal"
				ref={activeErrorModal}
			>
				Launch demo modal
			</button>
			{/* // <!-- Modal --> */}
			<div
				className="modal fade"
				id="succesModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalAfterContact"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div
						className="modal-content"
						style={{ border: "2px solid rgb(0, 209, 63)" }}
					>
						<div
							className="modal-header"
							style={{ backgroundColor: "rgba(0, 209, 63,0.7)" }}
						>
							<h5 className="modal-title" id="modalAfterContact">
								{t("messageResponse.positive.title")}
							</h5>

							<span
								aria-hidden="true"
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<i className="fi fi-br-cross"></i>
							</span>
						</div>
						<div className="modal-body">
							<p className="mb-0">
								{t("messageResponse.positive.firstSentence")}
								<br></br> {t("messageResponse.positive.secondSentence")}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="errorModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalAfterContact"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div
						className="modal-content"
						style={{ border: "2px solid rgb(255, 35, 0)" }}
					>
						<div
							className="modal-header"
							style={{ backgroundColor: "rgba(255, 35, 0, 0.7" }}
						>
							<h5 className="modal-title" id="modalAfterContact">
								{t("messageResponse.negative.title")}
							</h5>

							<span
								aria-hidden="true"
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
							>
								<i className="fi fi-br-cross"></i>
							</span>
						</div>
						<div className="modal-body">
							<p className="mb-0">
								{t("messageResponse.negative.firstSentence")} <br></br>{" "}
								{t("messageResponse.negative.secondSentence")}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
