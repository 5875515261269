import { PricingItemCard } from "./pricing-item-card";
import React from "react";
import { Link } from "react-router-dom";
export function Pricing({ t }) {
	const pricingItemData = [
		{
			classes: "bg-moto",
			iconUrl: "assets/img/moto-bg.png",
			translationKey: "rates.moto",
			length: null,
			price: 5,
		},
		{
			classes: "bg-car",
			iconUrl: "assets/img/tesla-bg.png",
			translationKey: "rates.auto",
			length: "<5m",
			price: 18,
		},
		{
			classes: "bg-minivan",
			iconUrl: "assets/img/minivan.png",
			translationKey: "rates.suv",
			length: "5-7m",
			price: 22,
		},
		{
			classes: "bg-rv",
			iconUrl: "assets/img/rv-bg.png",
			translationKey: "rates.camper",
			length: "7-10m",
			price: 30,
		},
		{
			classes: "bg-yacht",
			iconUrl: "assets/img/yacht.png",
			translationKey: "rates.yacht",
			length: ">10m",
			price: 36,
		},
	];

	return (
		<section id="pricing" className="pricing sections-white">
			<div className="container" data-aos="fade-up">
				<div className="section-header pb-4 pb-lg-2">
					<h2>{t("rates.title")}</h2>
					<p>{t("rates.description")}</p>
				</div>

				<div
					className="row g-4 py-lg-5 d-flex justify-content-around"
					data-aos="zoom-out"
					data-aos-delay="100"
				>
					{pricingItemData.map((item, index) => (
						<PricingItemCard key={index} item={item} t={t} />
					))}

					<div className="col-lg-4">
						<div className="pricing-item">
							<h3>{t("rates.benefits.title")}</h3>
							<ul className="text-dark">
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.one")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.two")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.three")}
								</li>
								<li>
									<i className="bi bi-check"></i> {t("rates.benefits.four")}
								</li>
								{/*! Riabilitare sconti se necessario  */}
								{/* <li style={{ fontWeight: "700" }}>
								<i className="fi fi-rs-sack-dollar"></i>{" "}
								{t("rates.benefits.five")}
								{""} 60 €
								</li> */}
							</ul>
						</div>
					</div>
				</div>

				<div className="section-header pb-4 pb-lg-2 pt-5">
					<h2 className="mb-md-0">{t("rates.additional_services.title")}</h2>
				</div>
				<div
					className="row g-4 py-lg-5"
					data-aos="zoom-out"
					data-aos-delay="100"
				>
					<div className="col-lg-6">
						<div className="pricing-item pricing-item-white-bg">
							<h3>{t("rates.additional_services.one")}</h3>

							<div className="icon">
								<i className="fi fi-rs-car-wash"></i>
							</div>

							<h4>
								<sup>€</sup>25<span> / {t("rates.price_from")} </span>
							</h4>

							<div className="text-center mt-4">
								<Link to="/home#carWash">{t("rates.button_find_more")}</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="pricing-item pricing-item-white-bg">
							<h3>{t("rates.additional_services.two")}</h3>

							<div className="icon">
								<i className="fi fi-rs-car-mechanic"></i>
							</div>

							<h4>
								<sup>€</sup>40<span> / {t("rates.price_from")} </span>
							</h4>

							<div className="text-center mt-4">
								<Link to="/home#mechanicAssistance">
									{t("rates.button_find_more")}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
