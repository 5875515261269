import i18next from "i18next";
import React from "react";
export function Footer({ t }) {
	const currentLanguage = !localStorage.getItem("lng")
		? i18next.language
		: localStorage.getItem("lng");

	return (
		<footer id="footer" className="footer">
			<div className="container">
				<div className="row gy-4 justify-content-between">
					<div className="col-lg-5 col-md-12 footer-info">
						<h1 className="name">Parking & Transfer Livorno</h1>
						<p className="mt-4">{t("footer.description")}</p>
						<div className="social-links d-flex mt-4">
							<a
								href="https://www.instagram.com/parkingtransferlivorno_mm/"
								rel="noreferrer"
								target="_blank"
								className="instagram"
								title="Follow us on Instagram"
							>
								<i className="bi bi-instagram"></i>
							</a>
							<a
								href="https://www.facebook.com/profile.php?id=100089913965805"
								rel="noreferrer"
								target="_blank"
								className="instagram"
								title="Follow us on Facebook"
							>
								<i className="bi bi-facebook"></i>
							</a>
						</div>
					</div>
					<div className="col-lg-6 d-flex flex-column align-items-center align-items-sm-start flex-sm-row justify-content-between">
						<div className=" col-12 col-sm-6 footer-links text-center text-md-start ">
							<h4> {t("footer.useful_link.title")}</h4>

							<ul>
								<li>
									<a
										href="https://www.portolivorno2000.it/web/guest/visualizza-partenze-traghetti"
										target="_blank"
										rel="noreferrer"
									>
										{t("footer.useful_link.one")}
									</a>
								</li>
								{/* <li>
									<a
										href="https://www.visit-livorno.it/en/"
										target="_blank"
										rel="noreferrer"
									>
										{t("footer.useful_link.two")}
									</a>
								</li> */}
								<li>
									<a
										href="https://www.visittuscany.com/en/"
										target="_blank"
										rel="noreferrer"
									>
										{t("footer.useful_link.three")}
									</a>
								</li>
							</ul>
						</div>

						<div className="col-12 col-sm-6  footer-contact text-center text-md-start ">
							<h4>{t("footer.contact")}</h4>
							<p>
								Via dei Materassai 1<br />
								57121, Livorno, LI
								<br />
								Italia
								<br />
								<br />
								<br />
								<strong>{t("footer.vat")}:</strong> 02019770490
								<br />
								<strong>Tel:</strong> +39 375 696 6969
								<br />
								<strong>Email:</strong>{" "}
								<span>info@parkingtransferlivorno.com</span>
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-4 d-flex flex-column align-items-center">
				<div className="legal-documents">
					{" "}
					{t("footer.documents")}:{" "}
					<span>
						<a
							href="document/Cookies_Policy_ParkingTransferLivorno.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Cookies
						</a>
					</span>{" "}
					{t("footer.and")}{" "}
					<span>
						<a
							href={
								currentLanguage === "it"
									? "document/Privacy_ParkingTransferLivorno.pdf"
									: "document/Privacy_ParkingTransferLivorno_EN.pdf"
							}
							target="_blank"
							rel="noreferrer"
						>
							Privacy Policy
						</a>
					</span>
				</div>
				<div className="copyright">
					&copy; Copyright{" "}
					<strong>
						<span>Parking & Transfer Livorno</span>
					</strong>
					. All Rights Reserved
				</div>
			</div>
		</footer>
	);
}
