import React, { useRef, useState } from "react";
import IntPhoneInput from "./phone";
import emailjs from "@emailjs/browser";
import i18next from "i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import InputVehicles from "./input-vehicles";

export function ContactForm({ activeSuccessModal, activeErrorModal, t }) {
	const [inquiryObject, setInquiryObject] = useState("parking");
	const [destinationTransfer, setDestinationTransfer] = useState("");
	const [additionalServices, setAdditionalServices] = useState("");
	const [phone, setPhone] = useState(""); // State for phone number
	const [pairVehicles, setPairVehicles] = useState([
		{ vehicleType: "", numberVehicles: "" },
	]);
	const [phoneError, setPhoneError] = useState(false); // State for validation error
	const form = useRef();

	const currentLanguage = !localStorage.getItem("lng")
		? i18next.language
		: localStorage.getItem("lng");

	// Function to add a new vehicle pair
	const addNewVehiclePair = () => {
		setPairVehicles([...pairVehicles, { vehicleType: "", numberVehicles: 0 }]);
	};

	// Function to remove a specific vehicle pair
	const removeVehiclePair = (index) => {
		const updatedPairVehicles = pairVehicles.filter((_, i) => i !== index);
		setPairVehicles(updatedPairVehicles);
	};

	const sendEmail = (e) => {
		e.preventDefault();

		// Validate phone number before sending email
		if (!phone || !isValidPhoneNumber(phone)) {
			setPhoneError(true);
			return;
		}
		setPhoneError(false);

		// Generate the list of vehicles based on pairVehicles state
		const vehiclesList = pairVehicles
			.map((vehiclePair) => {
				return `Vehicle: ${vehiclePair.numberVehicles} ${vehiclePair.vehicleType}`;
			})
			.join("\n"); // Join each pair into a new line

		console.log("vehiclesList", vehiclesList);
		const templateParams = {
			subject: e.target.subject.value,
			customer_name: e.target.customer_name.value,
			customer_surname: e.target.customer_surname.value,
			checkin_date: e.target.checkin_date.value,
			checkin_time: e.target.checkin_time.value,
			checkout_date: e.target.checkout_date.value,
			checkout_time: e.target.checkout_time.value,
			vehicles_list: vehiclesList,
			additional_services: e.target.additional_services.value,
			destination: e.target.destination?.value,
			imbarco: e.target.imbarco?.value,
			partenza_aereo_treno: e.target.partenza_aereo_treno?.value,
			partenza_traghetto: e.target.partenza_traghetto?.value,
			message: e.target.message?.value,
			customer_email: e.target.customer_email.value,
			customer_phone: e.target.customer_phone.value,
			personalData: e.target.personalData.checked ? "checked" : "unchecked",
		};

		emailjs
			.send(
				process.env.REACT_APP_SERVICE,
				process.env.REACT_APP_TEMPLATE,
				templateParams,
				process.env.REACT_APP_EMAILJS_USER_ID
			)
			.then(
				(result) => {
					activeSuccessModal.current.click();
					e.target.reset();
				},
				(error) => {
					activeErrorModal.current.click();
				}
			);
	};

	return (
		<>
			<div className="col-lg-7 col-xl-8">
				<form
					ref={form}
					onSubmit={sendEmail}
					method="post"
					className="php-email-form"
				>
					<div className="form-group mt-1">
						<select
							className="select-form-group form-control"
							aria-label="Select Subject"
							name="subject"
							onChange={(e) => setInquiryObject(e.target.value)}
							required
							defaultValue=""
						>
							<option hidden value="">
								{t("contact.fields.subject.placeholder")}
							</option>
							<option value="parking" type="text" className="form-control">
								{t("contact.fields.subject.option_two")}
							</option>
							<option
								value="parking&transfer"
								type="text"
								className="form-control"
							>
								{t("contact.fields.subject.option_three")}
							</option>
						</select>
						<label className="form__label">
							{" "}
							{t("contact.fields.subject.label")} *
						</label>
					</div>

					<div className="row">
						<div className="col-md-6 form-group">
							<input
								type="text"
								name="customer_name"
								placeholder={t("contact.fields.name.placeholder")}
								id="name"
								className="form-control"
								required
							/>
							<label className="form__label">
								{t("contact.fields.name.label")} *
							</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<input
								type="text"
								className="form-control"
								name="customer_surname"
								id="surname"
								placeholder={t("contact.fields.surname.placeholder")}
							/>
							<label className="form__label">
								{t("contact.fields.surname.label")} *
							</label>
						</div>
					</div>
					<div className="row mt-1">
						<div className="col-md-6 form-group">
							<input
								type="email"
								className="form-control"
								name="customer_email"
								id="email"
								placeholder={t("contact.fields.email.placeholder")}
								required
							/>
							<label className="form__label">
								{t("contact.fields.email.label")} *
							</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<IntPhoneInput
								name="customer_phone"
								// Pass the setPhone function
								onChange={setPhone}
							/>
							<label className="form__label">{t("contact.phone")} *</label>
							{phoneError && (
								<span
									className="text-danger position-absolute"
									style={{ fontSize: "10px", letterSpacing: "0.5px" }}
								>
									{t("contact.phone_error")}
								</span>
							)}
						</div>
					</div>

					<div className="row mt-1">
						<div className="col-md-6 form-group">
							<input
								className="form-control"
								type="date"
								placeholder=" "
								name="checkin_date"
								id="checkin"
								required
							/>
							<label className="form__label">Checkin *</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<input
								className="form-control"
								type="date"
								placeholder=" "
								name="checkout_date"
								id="checkout"
								required
							/>
							<label className="form__label">Checkout *</label>
						</div>
					</div>
					<div className="row mt-1">
						<div className="col-md-6 form-group">
							<input
								className="form-control"
								type="time"
								placeholder=" "
								name="checkin_time"
								id="checkinTime"
								required
							/>
							<label className="form__label">Checkin Time *</label>
						</div>
						<div className="col-md-6 form-group mt-1 mt-md-0">
							<input
								className="form-control"
								type="time"
								placeholder=" "
								name="checkout_time"
								id="checkoutTime"
								required
							/>
							<label className="form__label">Checkout Time *</label>
						</div>
					</div>

					{/* Vehicles type + number */}
					<InputVehicles
						pairVehicles={pairVehicles} // Pass the pairVehicles to the child
						setPairVehicles={setPairVehicles} // Pass setPairVehicles to handle updates
						removeVehiclePair={removeVehiclePair} // Pass the remove function
						t={t} //translation language
					/>

					{/* Button to add vehicles */}
					<div className="row">
						<div>
							<button
								type="button"
								className="form-group btn-add-vehicles"
								style={{ height: "min-content" }}
								onClick={addNewVehiclePair}
							>
								<p className="mb-0">
									<i className="bi bi-plus-circle-fill me-2"></i>Add vehicle
								</p>
							</button>
						</div>
					</div>

					<div className="row mt-1 ">
						<div className="w-100 form-group">
							<select
								className={`select-form-group form-control ${
									additionalServices === "" ? "not-required" : ""
								}`}
								aria-label="Additional Services"
								name="additional_services"
								defaultValue=""
								onClick={(e) => setAdditionalServices(e.target.value)}
							>
								<option value="" hidden>
									{t("contact.fields.additional_services.placeholder")}
								</option>
								<option
									value="Vehicle Wash"
									type="text"
									className="form-control"
								>
									{t("contact.fields.additional_services.option_one")}
								</option>
								<option
									value="Mechanic Assistance"
									type="text"
									className="form-control"
								>
									{t("contact.fields.additional_services.option_two")}
								</option>
								<option
									value="Vehicle Wash + Mechanic Assistance"
									type="text"
									className="form-control"
								>
									{t("contact.fields.additional_services.option_three")}
								</option>
								<option value="None" type="text" className="form-control">
									{t("contact.fields.additional_services.option_four")}
								</option>
							</select>
							<label className="form__label">
								{t("contact.fields.additional_services.label")}
							</label>
						</div>
					</div>
					{inquiryObject === "parking&transfer" && (
						<>
							<div className="row mt-1">
								<div
									className={`form-group ${
										![
											"AeroportoFlorence",
											"AeroportoPisa",
											"Stazione",
										].includes(destinationTransfer)
											? "w-100"
											: ""
									}`}
								>
									<select
										className="select-form-group form-control"
										aria-label="Select Destination"
										name="destination"
										onChange={(e) => setDestinationTransfer(e.target.value)}
										required
										defaultValue=""
									>
										<option hidden value="">
											{t("contact.fields.transfer.placeholder")}
										</option>
										<option
											value="AeroportoPisa"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_one")}
										</option>
										<option
											value="AeroportoFlorence"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_two")}
										</option>
										<option value="Centro" type="text" className="form-control">
											{t("contact.fields.transfer.option_three")}
										</option>
										<option value="Porto" type="text" className="form-control">
											{t("contact.fields.transfer.option_four")}
										</option>
										<option
											value="Stazione"
											type="text"
											className="form-control"
										>
											{t("contact.fields.transfer.option_five")}
										</option>
										<option value="other" type="text" className="form-control">
											{t("contact.fields.transfer.option_six")}
										</option>
									</select>
									<label className="form__label">
										{t("contact.fields.transfer.label")} *
									</label>
								</div>

								{["AeroportoFlorence", "AeroportoPisa", "Stazione"].includes(
									destinationTransfer
								) && (
									<div className="form-group">
										<input
											className="form-control"
											type="time"
											placeholder=" "
											name="partenza_aereo_treno"
											id="departureTime"
											required
										/>
										<label className="form__label">
											{t("contact.fields.departure_time.label")}{" "}
											{destinationTransfer === "stazione"
												? `${t("contact.fields.departure_time.train")}`
												: `${t("contact.fields.departure_time.airplane")}`}
											*
										</label>
									</div>
								)}
							</div>
							{destinationTransfer === "Porto" && (
								<div className="row mt-1">
									<div className="form-group">
										<select
											className="select-form-group form-control"
											aria-label="Select Destination"
											name="imbarco"
											required
											defaultValue=""
										>
											<option hidden value="">
												{t("contact.fields.embark.placeholder")}
											</option>
											<option
												value="Sardina - Grimaldi Lines"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.sardinia_grimaldi_lines")}
											</option>
											<option
												value="Sardinia - Sardinia Lines"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.sardinia_sardinia_lines")}
											</option>
											<option
												value="Sardinia - Moby Lines"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.sardinia_moby_lines")}
											</option>
											<option
												value="Corsica - Corsica Ferries"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.corsica_corsica_ferries")}
											</option>
											<option
												value="Corsica - Moby Lines"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.corsica_moby_lines")}
											</option>
											<option
												value="Capraia"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.capraia")}
											</option>
											<option
												value="Gorgona"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.gorgona")}
											</option>
											<option
												value="Altro Imbarco"
												type="text"
												className="form-control"
											>
												{t("contact.fields.embark.other")}
											</option>
										</select>
										<label className="form__label">
											{t("contact.fields.embark.label")} *
										</label>
									</div>

									<div className="form-group ">
										<input
											className="form-control"
											type="time"
											placeholder=" "
											name="partenza_traghetto"
											id="departureTime"
											required
										/>
										<label className="form__label">
											{t("contact.fields.departure_time.label")} *
										</label>
									</div>
								</div>
							)}
						</>
					)}

					<div
						className="form-group mt-1 mb-2"
						style={{ height: "fit-content" }}
					>
						<textarea
							className="form-control"
							name="message"
							rows="5"
							placeholder={t("contact.fields.message.placeholder")}
						></textarea>
						<label className="form__label">
							{t("contact.fields.message.label")}
						</label>
					</div>

					<div className="d-flex align-items-center pb-2">
						<input
							className="form-check-input mt-0"
							style={{
								marginRight: "0.5rem",
								aspectRatio: "1",
							}}
							type="checkbox"
							name="personalData"
							value="isAccepted"
							id="personalData"
							required
						/>
						<label className="form-check-label" htmlFor="personalData">
							{t("contact.fields.acceptance_data.text")}{" "}
							<a
								href={
									currentLanguage === "it"
										? "document/Privacy_ParkingTransferLivorno.pdf"
										: "document/Privacy_ParkingTransferLivorno_EN.pdf"
								}
								target="_blank"
								rel="noreferrer"
							>
								{t("contact.fields.acceptance_data.link")}
							</a>
							*
						</label>
					</div>
					<div className="text-center mt-2">
						<button type="submit">{t("contact.button_book")}</button>
					</div>
				</form>
			</div>
		</>
	);
}
